import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Table from '../../../../components/Table';
import { useTranslation } from 'react-i18next';
import useLabels from '../../hooks/useLabels';
import Modal from 'components/Modal';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';
import ManageStoresForm from '../../components/ManageStoresForm';
import { ManageStoresFormBody, Store, storeStatusType } from 'models/Stores';
import { toast } from 'react-toastify';
import api from 'api';
import usePaginatedItems from '../../../hooks/usePaginatedItems';
import { StoreSearchBy, storeSearchByOptions } from 'types';
import confirm from 'modules/confirm';
import utils from 'utils';
import useQueryParams from 'hooks/useQueryParams';
import { useQueryClient } from '@tanstack/react-query';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import { StaplesStore } from 'api/stores';
import { Link } from 'react-router-dom';

import './ManageStores.styles.scss';

const ManageStores: React.FC = () => {
  const { t } = useTranslation();
  const labels = useLabels();
  const modalRef = useRef<ModalRef>();
  const queryClient = useQueryClient();

  const [storeId, setStoreId] = useState<number>(null);

  const {
    setQueryParam,
    params: { page, search, limit, searchBy },
  } = useQueryParams<{
    page: string;
    search: string;
    limit: string;
    searchBy: StoreSearchBy;
  }>({ searchBy: 'name' });

  const { items, isRefreshing, isFetching, onRefresh, totalItems, totalPages } =
    usePaginatedItems({
      queryKey: 'stores',
      makeRequest: api.stores.getStores,
      searchParam: searchBy,
      params: {
        limit,
        page,
        [searchBy]: !!search && search?.length >= 2 ? search : undefined,
      },
    });

  useEffectSkipFirst(() => {
    page !== '1' && setQueryParam('page', '1', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, limit]);

  const searchByOptions = useMemo(
    () =>
      Object.values(storeSearchByOptions).map(({ value, label }) => ({
        label: t(`${label}`),
        value,
      })),
    [t],
  );

  const addStore = useCallback(
    async (values: ManageStoresFormBody) => {
      try {
        await api.stores.addStore(values);
        toast.success(t('successAddingStore'));
        modalRef.current.close();
        setStoreId(null);
        queryClient.invalidateQueries({
          queryKey: ['stores'],
        });
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    },
    [queryClient, t],
  );

  const updateStore = useCallback(
    async (values: ManageStoresFormBody, id: number) => {
      try {
        await api.stores.updateStore(values, id.toString());
        toast.success(t('successUpdatingStore'));
        modalRef.current.close();
        setStoreId(null);
        queryClient.invalidateQueries({
          queryKey: ['stores'],
        });
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    },
    [queryClient, t],
  );

  const deleteStore = useCallback(
    async (id: string) => {
      try {
        if (id) {
          await api.stores.deleteStore(id);
          toast.success(t('successDeletingStore'));
          onRefresh();
          queryClient.invalidateQueries({
            queryKey: ['stores'],
          });
        }
      } catch (error) {
        toast.error(t('General.error'));
      }
    },
    [onRefresh, queryClient, t],
  );

  const onSubmitStore = useCallback(
    async (values: ManageStoresFormBody, id: number) => {
      if (id) {
        updateStore(values, id);
      } else {
        addStore(values);
      }
    },
    [addStore, updateStore],
  );

  const handleDeleteStore = useCallback(
    async (values: Partial<Store>) => {
      const confirmDeletion = confirm({
        title: t('deleteAssociateTitle'),
        confirmBtnText: t('Delete'),
        content: t('deleteAssociate', {
          name: values?.name,
        }),
      });

      if (await confirmDeletion) {
        deleteStore(values?.storeNo.toString());
      }
    },
    [deleteStore, t],
  );

  const handleSearchByChange = useCallback(
    (option: OptionValue<any>) => {
      setQueryParam('searchBy', option.value, true);
    },
    [setQueryParam],
  );

  const renderContentArray = useCallback(
    (data: StaplesStore[]) =>
      data?.map(({ statusId, storeNo, name, ...rest }) => ({
        nameLink: (
          <Link
            className="store-link"
            to={`/manage-stores/${storeNo}/products`}
          >
            {name}
          </Link>
        ),

        storeNo,
        name,
        status: t(storeStatusType[statusId]),
        ...rest,
      })),
    [t],
  );

  useEffect(() => {
    modalRef.current.close();
  }, []);

  return (
    <>
      <Modal
        focusableElements="div"
        type="no-action"
        ref={modalRef}
        modalName="manage-stores__modal"
        onX={() => {
          setStoreId(null);
          modalRef.current.close();
        }}
        onClose={() => {
          setStoreId(null);
        }}
        title={storeId ? t('editStore') : t('addStore')}
      >
        <ManageStoresForm
          onSubmit={(values) => onSubmitStore(values, storeId)}
          inProgress={isRefreshing}
          storeId={storeId}
          handleCancel={() => {
            setStoreId(null);
            modalRef.current.close();
          }}
        />
      </Modal>
      <Table
        className="manage-stores__table"
        keyName="storeNo"
        fetching={isFetching}
        totalItems={totalItems}
        title={t('stores')}
        labels={labels}
        elementProps={labels.map(({ key }) => key)}
        contentArray={renderContentArray(items)}
        buttonText={t('addStore')}
        onAddButtonClick={() => {
          modalRef.current.open();
        }}
        totalPages={totalPages}
        searchable
        onEdit={(el) => {
          setStoreId(+el.storeNo);
          modalRef.current.open();
        }}
        onDelete={(store) => handleDeleteStore(store)}
        withActionButtons
        searchByOptions={searchByOptions}
        searchBy={searchBy}
        limit={limit}
        onSearchByChange={handleSearchByChange}
        searchPlaceholder={t('searchStorePlaceholder')}
      />
    </>
  );
};

export default ManageStores;
