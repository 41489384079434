import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../components/Table';
import usePaginatedItems from '../hooks/usePaginatedItems';
import api from 'api';
import useLabels from './hooks/useLabels';
import useQueryParams from 'hooks/useQueryParams';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import {
  ChangePriceBody,
  ProductCatalogPriceForm,
  StaplesProduct,
} from 'models/Products';
import { parseVersion } from './utils';
import {
  ProductUploadType,
  ProductSearchBy,
  productSearchByOptions,
} from 'types';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import Modal, { ModalRef } from 'components/Modal';
import ChangePriceForm from './components/ChangePriceForm';
import { useQueryClient } from '@tanstack/react-query';
import UploadFileForm from '../ManageStores/components/UploadFileForm';
import { toast } from 'react-toastify';
import utils from 'utils';

const ProductCatalog: React.FC = () => {
  const { t } = useTranslation();
  const labels = useLabels();

  const queryClient = useQueryClient();

  const [selectedProduct, setSelectedProduct] = useState<ChangePriceBody>();

  const [formType, setFormType] = useState<ProductUploadType>('');

  const modalRef = useRef<ModalRef>();

  const {
    setQueryParam,
    params: { page, search, limit, searchBy },
  } = useQueryParams<{
    page: string;
    search: string;
    limit: string;
    searchBy: ProductSearchBy;
  }>({ searchBy: 'name' });

  const { items, isFetching, isRefreshing, totalPages, totalItems, onRefresh } =
    usePaginatedItems({
      queryKey: 'product-catalog',
      makeRequest: api.products.getProducts,
      searchParam: searchBy,
      params: {
        limit,
        page,
        [searchBy]: !!search && search?.length >= 2 ? search : undefined,
      },
    });

  const modalTitle = useMemo(() => {
    switch (formType) {
      case 'productUpload':
        return t('uploadProductsFile');
      case 'upcMapping':
        return t('uploadUPCMappingFile');
      case 'editProduct':
        return t('editChainPrice');
      default:
        return;
    }
  }, [formType, t]);

  const uploadProducts = useCallback(
    async ({ attachment }: { attachment: File[] }) => {
      try {
        const formData = new FormData();
        formData.append('file', attachment[0]);
        await api.stores.uploadProducts(formData);
        toast.success(t('successUploadingProductsFile'));
        modalRef.current.close();
        setFormType('');
        onRefresh();
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    },
    [onRefresh, t],
  );

  const uploadUPCMapping = useCallback(
    async ({ attachment }: { attachment: File[] }) => {
      try {
        const formData = new FormData();
        formData.append('file', attachment[0]);
        await api.stores.uploadUPCMapping(formData);
        toast.success(t('successUploadingUPCMappingFile'));
        modalRef.current.close();
        setFormType('');
        onRefresh();
      } catch (error) {
        toast.error(utils.handleResponseError(error));
      }
    },
    [onRefresh, t],
  );
  const renderContentArray = useCallback((data: StaplesProduct[]) => {
    return data?.map(
      ({ imageUrls = [], name, skuNo, version, upcs, ...rest }) => ({
        imageUrl: imageUrls[0],
        name,
        skuNo,
        upc: upcs[0]?.code,
        id: `${skuNo}`,
        lastUpdate: parseVersion(version),
        version,
        ...rest,
      }),
    );
  }, []);

  const handleSearchByChange = useCallback(
    (option: OptionValue<any>) => {
      setQueryParam('searchBy', option.value, true);
    },
    [setQueryParam],
  );

  const onUploadFile = useCallback(
    async ({ attachment }: { attachment: File[] }) => {
      if (formType === 'productUpload') {
        uploadProducts({ attachment });
      } else if (formType === 'upcMapping') {
        uploadUPCMapping({ attachment });
      }
    },
    [formType, uploadProducts, uploadUPCMapping],
  );

  useEffectSkipFirst(() => {
    page !== '1' && setQueryParam('page', '1', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, limit]);

  const searchByOptions = useMemo(
    () =>
      Object.values(productSearchByOptions).map(({ value, label }) => ({
        label: t(`${label}`),
        value,
      })),
    [t],
  );

  const onSubmit = useCallback(
    async (values: ProductCatalogPriceForm) => {
      const { skuNo, expirationDate, version } = selectedProduct;
      const { promoPrice, regularPrice } = values;
      try {
        await api.products.updateChainProductPrices({
          skuNo,
          expirationDate,
          version,
          promoPrice: +promoPrice,
          regularPrice: +regularPrice,
        });
        queryClient.invalidateQueries({
          queryKey: ['product-catalog'],
        });
        setFormType('');
        setSelectedProduct(null);
      } catch (err) {
        console.error(err);
      } finally {
        modalRef.current.close();
      }
    },
    [queryClient, selectedProduct],
  );

  return (
    <>
      <Modal
        focusableElements="div"
        type="no-action"
        ref={modalRef}
        modalName="change-price"
        onClose={() => setSelectedProduct(null)}
        onX={() => {
          setFormType('');
          setSelectedProduct(null);
          modalRef.current.close();
        }}
        title={modalTitle}
      >
        {formType === 'editProduct' ? (
          <ChangePriceForm
            inProgress={isRefreshing}
            onSubmit={(values) => onSubmit(values)}
            prices={{
              regularPrice: selectedProduct?.regularPrice,
              promoPrice: selectedProduct?.promoPrice,
            }}
            handleCancel={() => {
              setFormType('');
              setSelectedProduct(null);
              modalRef.current.close();
            }}
          />
        ) : (
          <UploadFileForm
            onSubmit={onUploadFile}
            inProgress={isRefreshing}
            onClose={() => setFormType('')}
            handleCancel={() => {
              modalRef.current.close();
            }}
            formType={formType}
            fileType=".xlsx"
          />
        )}
      </Modal>
      <Table
        fetching={isFetching}
        title={t('productCatalog')}
        totalItems={totalItems}
        searchable
        withActionButtons
        onEdit={(values) => {
          setFormType('editProduct');
          setSelectedProduct(values);
          modalRef.current.open();
        }}
        labels={labels}
        onUploadProducts={() => {
          setFormType('productUpload');
          modalRef.current.open();
        }}
        onUploadUPCMapping={() => {
          setFormType('upcMapping');
          modalRef.current.open();
        }}
        elementProps={labels.map(({ key }) => key)}
        cellComponentMap={{ imageUrl: 'ProductImage' }}
        contentArray={renderContentArray(items)}
        totalPages={totalPages}
        limit={limit}
        searchBy={searchBy}
        searchByOptions={searchByOptions}
        onSearchByChange={handleSearchByChange}
        searchPlaceholder={t('searchProductPlaceholder')}
      />
    </>
  );
};

export default ProductCatalog;
